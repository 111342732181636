import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { base } from "../../../base";

/* TODO :
  Remplacer le système de login par le Firebase Auth SDK
*/

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      loggedIn: false
    };
  }

  handleChange = event => {
    const password = event.target.value;
    this.setState({
      password
    });
  };

  handleLogin = event => {
    event.preventDefault();
    // on check avec la bdd
    return base
      .collection("credentials")
      .doc("admin")
      .get()
      .then(doc => {
        const data = doc.data();
        if (this.state.password === data.password) {
          return this.setItem("isLoggedIn", true).then(() => {
            this.setState({
              loggedIn: true
            });
          });
        }
      });
    // if (password === this.state.password) {
    //   console.log("hello");
    // }
  };

  async setItem(key, value) {
    await null;
    return localStorage.setItem(key, value);
  }

  render() {
    return this.state.loggedIn ? (
      <Redirect to="/admin" />
    ) : (
      <form className="login-form" onSubmit={event => this.handleLogin(event)}>
        <p className="form-title">Cette page est protégée.</p>
        <label>
          <span>Mot de passe</span>
          <input
            type="password"
            value={this.state.password}
            onChange={event => this.handleChange(event)}
          />
        </label>
        <button className="btn-submit" type="submit">
          Go
        </button>
      </form>
    );
  }
}
