import React, { Component } from "react";
import { base } from "../../base";
import Logo from "../../assets/images/email_logo.png";

export default class Signature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id_signature,
      name: "",
      lastName: "",
      email: "",
      numbers: [],
      photo: ""
    };

    const data = base.collection("emails").doc(`${this.state.id}`);

    data.get().then(doc => {
      const fullData = doc.data();
      const stateCopy = { ...this.state };

      stateCopy.name = fullData.name;
      stateCopy.lastName = fullData.lastName;
      stateCopy.numbers = fullData.numbers;
      stateCopy.email = fullData.email;
      stateCopy.photo = fullData.photo;

      this.setState(stateCopy);
    });
  }

  removeSpaces = number => {
    console.log(number);

    const finalNumber = number.split(" ").join("");

    return finalNumber;
  };

  render() {
    const imageLinkStyle = {
      display: "block",
      height: "18px",
      width: "18px",
      marginRight: "18px"
    };

    const imageStyle = {
      height: "18px",
      width: "auto"
    };

    const numberList = {
      fontFamily: "Arial, sans-serif",
      color: "#2e2e2e",
      fontSize: "13px",
      fontWeight: "bold",
      lineHeight: "1",
      textDecoration: "none"
    };

    return (
      <>
        <style>{`\
      body{\
        margin:0;\
        padding:0;\
    }\

    img {\
        border:0 none;\
        height:auto;\
        line-height:100%;\
        outline:none;\
        text-decoration:none;\
    }\

    a img{\
        border:0 none;\
    }\

    .imageFix{\
        display:block;\
    }\

    table, td{\
        border-collapse:collapse;\
    }\

    #bodyTable{\
        height:100% !important;\
        margin:0;\
        padding:0;\
        width:100% !important;\
    }\
    `}</style>
        <table
          style={{
            width: "100%",
            maxWidth: "500px",
            margin: 0,
            paddingTop: "10px",
            marginBottom: "10px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
            display: "block"
          }}
          width="500"
        >
          <tbody>
            <tr>
              {this.state.photo.length > 1 ? (
                <>
                  <td style={{ verticalAlign: "top" }}>
                    <img
                      style={{ height: "210px" }}
                      alt="Good-One"
                      src={this.state.photo}
                    />
                  </td>
                  <td
                    style={{
                      fontSize: "1px",
                      lineHeight: "1px",
                      border: "0px",
                      padding: "0px",
                      width: "25px"
                    }}
                  />
                  <td
                    style={{
                      width: "1px",
                      backgroundColor: "#dcdcdc",
                      height: "100%"
                    }}
                  ></td>
                  <td
                    style={{
                      fontSize: "1px",
                      lineHeight: "1px",
                      border: "0px",
                      padding: "0px",
                      width: "25px"
                    }}
                  />
                </>
              ) : null}
              <td style={{ verticalAlign: "top" }}>
                <h2
                  style={{
                    fontFamily: "Arial, sans-serif",
                    fontSize: "17px",
                    color: "#2e2e2e",
                    fontWeight: "bold",
                    lineHeight: "1"
                  }}
                >
                  {this.state.name}
                  <br />
                  {this.state.lastName}
                </h2>
                <p
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#eec821",
                    fontSize: "12px",
                    letterSpacing: "4px",
                    margin: "10px 0 11px 0",
                    fontWeight: "bold",
                    lineHeight: "1"
                  }}
                >
                  GOOD ONE
                </p>
                <p
                  style={{
                    fontFamily: "Arial, sans-serif",
                    fontSize: "11px",
                    letterSpacing: "2px",
                    color: "#2e2e2e",
                    lineHeight: "1.25",
                    marginBottom: "12px"
                  }}
                >
                  UN SERVICE LUMINEUX
                  <br />
                  EN ÉLECTRICITÉ
                  <br />
                  ET DOMOTIQUE
                </p>
                <ul>
                  {this.state.numbers.map((number, index) => {
                    if (number.includes("(urgences)")) {
                      console.log(number.split("(urgences)"));
                      number = number.split("(urgences)")[0];

                      const formattedNumber = this.removeSpaces(number);

                      return (
                        <li key={index}>
                          <a href={`tel:${formattedNumber}`} style={numberList}>
                            {number}(urgences)
                          </a>
                        </li>
                      );
                    }

                    const formattedNumber = this.removeSpaces(number);

                    return (
                      <li key={index}>
                        <a href={`tel:${formattedNumber}`} style={numberList}>
                          {number}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <a
                  href="https://www.good-one.fr"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                  style={{
                    display: "block",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "13px",
                    color: "#2e2e2e",
                    fontWeight: "bold",
                    marginBottom: "13px",
                    marginTop: "13px",
                    textDecoration: "none",
                    lineHeight: "1"
                  }}
                >
                  www.good-one.fr
                </a>
                <ul>
                  <li style={{ display: "inline-block" }}>
                    <a
                      href="https://www.facebook.com/good.one.alsace/"
                      style={imageLinkStyle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={imageStyle}
                        src="https://www.good-one.fr/wp-content/uploads/2020/01/facebook.png"
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li style={{ display: "inline-block" }}>
                    <a
                      href="https://www.instagram.com/good.one.alsace/"
                      style={imageLinkStyle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={imageStyle}
                        src="https://www.good-one.fr/wp-content/uploads/2020/01/instagram.png"
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li style={{ display: "inline-block", marginLeft: "5px" }}>
                    <a
                      href="https://www.good-one.fr"
                      style={imageLinkStyle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={imageStyle}
                        src="https://www.good-one.fr/wp-content/uploads/2020/01/site.png"
                        alt="site"
                      />
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}
