import firebase from "firebase/app";
import "firebase/firestore";
import Rebase from "re-base";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDv33zZbG_mTGSX6s2Co-PK_RQtMfPqCy0",
  authDomain: "mails-goodone.firebaseapp.com",
  databaseURL: "https://mails-goodone.firebaseio.com",
  projectId: "mails-goodone"
});

export const base = firebaseApp.firestore();

const db = Rebase.createClass(base);

export default db;
